@import '../../style/variables';
.featured-box{
    display: flex;
    width: 100%;

    .thumbnail img{
        border-radius: 50%;
        margin: 1rem 8px;
        width: 145px;
        height: 145px;
    }

    .box-content{
        border-radius: 8px;
        border-top: 4px solid $color-brand;
        padding: 30px 30px 10px 30px;
        margin-left: 50px;
        margin-bottom: 50px;
        background: linear-gradient(to bottom, white 1%, #f9f9f9 98%) repeat scroll 0 0 transparent;
        box-shadow: 0px 9px 14px 8px rgba(0, 0, 0, 0.04);

        h4{
            font-size: 1.3em;
            font-weight: 700;
            letter-spacing: -0.7px;
            margin-top: 5px;
            text-transform: uppercase;
            color: $color-brand;
        }
    }
}