.traning-header {
  /* background: linear-gradient(#6726d6 0.01%, #5c10e3 100.01%); */
  /* background-image: url('../../Image/course/bg_sub-header.png'), linear-gradient(#6726d685 0.01%, #5d10e386 100.01%);
  background-image: url('../../Image/course/bg_sub-header.png'), -moz-linear-gradient(#6726d685 0.01%, #5d10e386 100.01%); */
  background: #040d21 url("../../Image/course/hero-bg.svg") no-repeat center
    center;
  height: 600px;
  /* clip-path: polygon(0 0, 100% 0, 100% 70%, 66% 93%, 27% 79%, 0 100%); */
  clip-path: polygon(0 0, 100% 0%, 100% 81%, 0 100%);
  padding-top: 60px;
  /* margin-top: -400px; */
}

.text-section {
  color: #c0cce0;
  text-align: center;
  padding: 50px 20px;
  width: 67%;
  margin: auto;

  background-color: #8193b234;
  border-radius: 16px;
}

.text-section > h1 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 72px;
  font-weight: 700;
}

.text-section > h3 {
  font-size: 38px;
}

.text-section > p {
  font-size: 24px;
  padding: 10px 120px 0px 120px;
}

.card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-top: 50px; */
  transition: 200ms linear;
  /* margin-bottom: 80px !important; */
  border-radius: 8px;
}
.card:hover {
  transform: scale(1.05);
  /* transform: rotate(360deg);  */
  transition: 300ms linear;
}

.card .card-discount {
  color: white;
  background: #f90462;
  /* z-index: 1; */
  position: absolute;
  top: 0px;
  left: -6px;
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0px;
  /* font-family: Baloo Da; */
  font-weight: 700;
  max-width: 100%;
  height: 30px;
  padding: 0 8px;
  white-space: nowrap;
}

.card .card-discount::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  border-width: 3px;
  border-style: solid;
  border-color: #c2004a #c2004a transparent transparent;
}

.card-img,
.card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-body {
  padding-bottom: 10px !important;
}

.card-body h5 {
  font-weight: bold;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.card-footer .price {
  display: flex;
}

.card-footer .price > p:nth-child(1) {
  color: red;
  text-decoration: line-through;
  padding-right: 10px;
}

.ReactModal__Overlay {
  background-color: rgba(20, 0, 34, 0.75) !important;
}

.ReactModal__Content {
  border-radius: 8px !important;
  z-index: 3 !important;
}

/* for responsive trainingCourse  */
@media (max-width: 1200px) {
  .traning-header {
    height: 600px;
  }
  .text-section > p {
    font-size: 18px;
    padding: 0 !important;
  }
  .text-section > h1 {
    font-size: 40px;
    font-weight: 400;
  }
  .text-section > h3 {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .all-course-header-text {
    font-size: 2rem !important;
  }

  .all-course-section {
    padding: 60px 30px !important;
  }
}

@media (max-width: 420px) {
  .all-course-header-text {
    font-size: 1.5rem !important;
  }

  .all-course-section {
    padding: 60px 10px !important;
  }
}

@media (max-width: 360px) {
  .traning-header {
    height: 700px;
  }
  .all-course-section {
    padding: 60px 0px !important;
  }
}
