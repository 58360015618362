@import "../../style/variables";
#about {
  padding: 0 80px;
  max-width: $max-width;
}

.main-title {
  color: $color-brand;
  padding: 20px 0;
  font-weight: 700;
}

#about .about-section {
  text-align: justify;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
}

#about .about-section .row {
  margin: 0;

  .col {
  }

  .true {
    border-top: 5px solid $color-brand;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  span {
    font-weight: 600;
  }
}

#about .about-section .row div {
  border: 1px solid #eeeeee;
  text-align: center;
  padding: 8px;
  color: $color-brand;
  background-color: #f9f9f9;
  cursor: pointer;
}

#about .about-section p {
  padding: 15px;
}

.about-summary {
  span {
    color: $color-brand;
  }
}
