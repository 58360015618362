.login {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  min-height: 65vh;
}
.login-form {
  min-width: 280px;
  max-width: 45%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 40px;
  margin-top: -25px;
  box-shadow: 2px 2px 25px #b8b8b8;
}
h2 {
  margin-bottom: 30px;
}
p {
  font-size: 1.2rem;
}
.logoIcon {
  margin-top: 20px;
  height: 50px;
  border-radius: 50px;
}
.googleLogin {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 57px;
  min-width: 300px;
  max-width: 90%;
  height: 45px;
  margin-bottom: 10px;
  cursor: pointer;
}

.googleLogin > p {
  padding-top: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "roboto";
}

.googleLogo {
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0.4rem;
  border-radius: 50px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  .login-form {
    max-width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  .googleLogin {
    min-width: 100% !important;
  }

  .googleLogin > p {
    padding-top: 14px;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: "roboto";
  }

  .googleLogo {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: 0.4rem;
    border-radius: 50px;
  }
}

@media only screen and (max-width: 320px) {
  /* For mobile phones: */

  .login-form {
    min-width: 260px;
  }

  .googleLogin {
    min-width: 100% !important;
  }

  .googleLogin > p {
    padding-top: 18px;
    font-size: 1rem;
    margin-left: 5px;
  }
}
