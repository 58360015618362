#panel1a-header:hover {
  color: white !important;
  background-color: #5d10e3 !important;
}
.descriptionStyle {
  background-color: #edefff;
}
.your_className {
  background-color: #5d10e3 !important;
  color: white !important;
}
.iconActiveDsn {
  color: white !important;
}
