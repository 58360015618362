@import "../../style/variables";


#services{
    padding: 60px 80px;
    max-width: $max-width;
    .main-title{
        color: $color-brand;
        margin-bottom: 20px;
    }

    .card{
        border: none;
        border-radius: 5px ;
        transition: all 1s;
        box-shadow: 0px 9px 14px 1px rgba(0, 0, 0, 0.04);

        &:hover{
            transform: translateY(-20px);
        }

        .card-title{
            color: #232323;
        }
    }
    
}
