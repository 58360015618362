#footer {
  // background-image: linear-gradient(
  //   210deg,
  //   #00afef 0%,
  //   #02749e 100%
  // ) !important;
  background: linear-gradient(#6726d6 0.01%, #5c10e3 100.01%);
  // background-color: #5c10e3;
  padding-top: 20px;
  // position: absolute;
  // bottom: 0px;
}
.footer-area {
  z-index: 0;
}
.copyright {
  border-top: 1px solid #8157ca;
  padding: 20px 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.f-contact {
  color: #fff;
  line-height: 27px;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 13px;
}

.copyright a {
  color: #ffffff;
  font-weight: 700;
}
.ulink ul {
  list-style: none;
  padding: 0;
  font-size: 14px;
  /* text-transform: uppercase; */
  letter-spacing: -0.02em;
  /* line-height: 1.2em; */
  font-weight: 600;
  /* color: #fff; */
}
.ulink ul li a {
  color: #fff;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}
.f-heading {
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  font-size: 20px;
  padding-left: 0;
  margin-top: 15px;
  padding-bottom: 0px;
}

.f-social {
  a {
    padding: 0 10px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}
