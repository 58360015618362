@import "../../style/variables";

.topnavbar {
  .top-nav {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 60px;
  }

  .nav-item {
    margin: 0 3px;
    &.last-child {
      margin-right: 0px;
    }
    a {
      width: 30px;
      height: 30px;
      margin: -1px 5px 5px 0;
      padding: 0;
      border-radius: 100%;
      box-shadow: 0 2px 2px 0 #000000bf;
      .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
