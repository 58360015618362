.img-container{

}



.img {
    border-radius: 100%;
    /* min-width: 250px;
    min-height: 250px; */
    width: 300px;
    height: 300px;
    margin-bottom: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.inner-section{
    max-width: 1410px;
    margin: auto;
}

@media (max-width: 700px) {
    .nameDesign {
      margin-top: 10px;
    }
  }