@import "../style/variables";

.contact{
    max-width: $max-width;
    margin: 0 auto;
    padding: 40px 40px;

    .row{
        width: 100%;
        .main-title{
            color: $color-brand;
        }

        .address{
            font-size: 1rem;

            .MuiSvgIcon-root{
                font-size: 14px;
            }
            
        }
    }

    hr{
        background-image: linear-gradient(to right, transparent, #5454af6e, transparent);
        // background-image: linear-gradient(to right, purple, #5454af6e, purple);
        border: 0;
        height: 1px;
        margin: 32px 0 32px 0;
    }

    .card {
        background-color: #fff;
        -webkit-box-shadow: 0 0 10px 0 #00000026;
        box-shadow: 0 0 10px 0 #00000026;
        border: transparent;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        margin-bottom: 30px;
        padding-bottom: 10px;
    }
    
    
    .card:hover {
        -webkit-transform: translateY(-10px);
        -webkit-transition: 0.5s;
        transition: 0.5s;
        box-shadow: 0px 2px 2px #bcbcbc;
        // background: linear-gradient(45deg, #8353d5 0.01%, #5706e4 100.01%);
        background: #5d10e390;
        .form-container> b{
            color: white;
        }
    }
}


.location{
    font-size: 1rem;

    .MuiSvgIcon-root{
        font-size: 14px;
    }

    .location-title{
        margin:  0;
    }
    
}